.tbl-quote-tasks {
  th,
  td {
    vertical-align: middle;
  }
  .task-actions {
    width: 80px;
    float: left;
    border-right: 1px solid #999;
    margin-right: 5px;
    padding-right: 5px;
    text-align: right;
  }
  .btn-add-to-step {
    border-color: "#8e104b";
    background-color: "#fff";
    padding: 5px;
    font-size: 10px;
    margin-top: 5px;
    margin-right: 15px;
  }
}
.mode_switcher_wrap {
  border: 1px solid #777;
  border-radius: 3px;
  overflow: hidden;

  .mode_switcher {
    display: inline-block;
    padding: 8px 15px;
    border-right: 1px solid #777;
    text-align: center;
    cursor: pointer;
  }
  .mode_switcher.active {
    background-color: #2196f3;
    color: #fff;
    font-weight: 500;
  }
  .mode_switcher:last-child {
    border: none;
  }
}

.task_desc {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.nested_task.hide_add_btn .gantt_add {
  display: none !important;
}

.marker-tooltip {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  padding: 10px;
  top: -80px;
  left: 18px;
  max-width: 350px;
  min-width: 250px;

  .title {
    // float: left;
    font-weight: 500 !important;
    font-size: 15px !important;
  }
  .close {
    // float: right;
    cursor: pointer;
    padding: 12px;
  }

  .address {
    margin: 0;
    margin-bottom: 8px;
  }

  ul {
    margin: 0;
    padding-left: 15px;
  }

  ul.full-height-scroll {
    max-height: 150px;
    overflow-y: auto;
  }
}
