.destination-group{
  .input-group{
    div:nth-child(2){
      flex: 1 !important;
    }
  }
}
.order-article{
  border: 1px solid #ddd;
  padding: 15px 10px;
  margin-bottom: 10px;
  
  .rm-article{
    font-size: 30px;
    border-left: 1px solid #888;
    padding-top: 15px;
    height: 65px;
    padding-left: 10px;

    i{
      color: #f44336;
      cursor: pointer;
    }
  }
}

.image_holder{
  border: 2px solid #ddd;
  border-style: dashed;
  border-radius: 10px;

  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 100px;

  input[type=file]{
    display: none;
  }

  .icon-cam{
    cursor: pointer;
    color: #6D7995;
    font-size:30px;
    display: block;
  }
  .attach-holder{
    cursor: pointer;
    // width: 34px;
    // height: 23px;
    color: #6d7995;
    font-size: 14px;
  }
}

.prev_img{
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  img{
    border-radius: 10px;
    position: relative;
  }
  
  .rm-icon{
    background: #f44336;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    left: unset;
    bottom: -10px;
    right: -5px;
    padding-top: 2px;
    text-align: center;

    i{
      color: #fff;
      font-size: 18px;
    }
  }
  
}

