.menu-wraper {
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;

  .left-side {
    img {
      width: 130px;
    }
  }

  .right-side {
    text-align: right;
    margin-top: 15px;

    .menu-link {
      color: #264b9a;
      margin: 10px;
      font-weight: 500;
      font-size: 15px;
    }
    .menu-btn {
      color: #264b9a;
      margin: 10px;
      padding: 5px 15px;
      border: 1px solid #264b9a;
      border-radius: 20px;
      font-weight: 500;
      font-size: 15px;
    }

    .menu-btn:hover {
      background-color: #264b9a;
      color: #fff;
    }

    .menu-toggle {
      color: #264b9a;
      margin-left: 10px;
      padding: 5px;
      border: 1px solid #264b9a;
      border-radius: 50%;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      width: 30px;
      display: inline-block;
      height: 30px;
      text-align: center;
    }
  }

  .right-side.white{
    .menu-link {
      color: #fff;
    }
    .menu-btn {
      color: #fff;
      border: 1px solid #fff;
    }
    .menu-btn:hover {
      background-color: #fff;
      color: #264b9a;
    }
    .menu-toggle {
      color: #fff;
      border: 1px solid #fff;
    }
  }
}
.right-menu-bar {
  background: #264b9a 0% 0% no-repeat padding-box;
  opacity: 0.7;

  .close-icon {
    font-size: 17px;
    color: #fff;
  }

  .right-menu-items {
    list-style-type: none;
    margin-top: 50px;

    li {
      margin-bottom: 20px;

      .sm-underline {
        display: block;
        background-color: #ff8000;
        width: 20px;
        height: 1px;
        margin-top: 2px;
      }

      a {
        color: #fff;
        font-weight: 500;
        font-size: 20px;
      }

      a:hover {
        .sm-underline {
          width: 40px;
        }
      }
    }
  }
}
.main-section {
  background-image: url("../images/bg-home.png");
  background-size: cover;
  min-height: 600px;
  padding-top: 250px;

  margin-top: -80px;

  .discover {
    color: #062342;

    .title {
      font-weight: 500;
      margin-bottom: 0;
    }
    .short-desc {
      color: #777;
    }

    .btn-discover {
      color: #fff;
      text-transform: uppercase;
      border-radius: 50px;
      min-width: 130px;
      font-weight: 500;
    }
  }
}
.separation-note {
  text-align: right;
  font-size: 17px;
  font-weight: 500;

  .mark-line {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 12px;
    width: 60px;
    height: 2px;
    background-color: #264b9a;
  }

  .mark-note {
    display: inline-block;
  }
}
.services-section {
  background-color: #fff;
  padding: 50px;

  .selected-index {
    font-size: 16px;
    color: #264b9a;
  }
}
.points-section {
  text-align: center;
  background-color: #fff;
  padding: 50px;

  img {
    width: 70px;
    height: 60px;
    margin-bottom: 10px;
  }
}
.quotation-section {
  text-align: center;
  padding: 100px;
  padding-top: 70px;

  background-image: url("../images/bg-grey.png");
  background-size: cover;

  .quotaion-icon {
    color: #ddd;
    font-size: 55px;
  }
}

.mobile-section {
  background-color: #fff;
  padding: 50px;

  .mobiles {
    width: 200px;
  }
}
.footer-wraper {
  background-color: #264b9a;
  padding: 15px;

  .logo-white {
    width: 70px;
  }

  .copyright {
    text-align: right;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
  }
}





// ABOUT US (page)
.question-section {
  background-image: url("../images/bg-about-us.png");
  background-size: cover;

  text-align: center;
  min-height: 200px;
  padding-top: 80px;

  margin-top: -80px;

  .question{
    .title{
      color: #fff;
    }

    .sm-underline {
      display: block;
      background-color: #ff8000;
      width: 20px;
      height: 1px;
      margin-top: 2px;
    }
  }
}

.explication-section{
  background-color: #fff;

  .title{
    border-left: 1px solid #ff8000;
    padding-left: 30px;
  }
}