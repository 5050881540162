.autocomplete-dropdown-container{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
  
  
    .suggestion-item{
      background-color: #fff;
      cursor: 'pointer';
      padding: 5px
    }
    .suggestion-item--active{
      background-color: #fafafa;
      cursor: 'pointer';
      padding: 5px
    }
  }