form.checkoutView.noImage {
    width: 21% !important;
}

button.StripeCheckout {
    span {
        background: #264b9a !important;
        width: 127px;
        line-height: 1.5;
    }
}
span.iconTick {
    background: #264b9a !important;
}
.button>.inner>button>span{
    background: #264b9a !important;

}