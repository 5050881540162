.page-content {
  min-height: 350px;
}

.login-logo-wrap {
  width: 130px;
  border-radius: 4px;
  background-color: #fff;

  img {
    width: 100%;
  }
}

.navbar-header-blue {
  background-color: #264b9a;
}
.navbar-brand-box {
  background-color: #fff;
}

// .sidebar-menu{
// li.mm-active.waves-effect 

li.parent.mm-active{
  background-color: #f3e6df;
  border-left: 3px solid #ec9962;
}
// .child.mm-active{
//   background-color: #f3e6df;
//   border-left: 3px solid #ec9962;
// }
ul.sub-menu.mm-collapse.mm-show {
  background: white;
}
.child-menu {
  &:focus,&:visited,&:target,&:focus-within {
    background-color: #f3e6df;
    border-left: 3px solid #ec9962;
  }
}
.child {
  &:focus,&:visited,&:target,&:focus-within {
    background-color: #f3e6df;
    border-left: 3px solid #ec9962;
  }
}
.navigation-title {
  margin-top: 18px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.appli-table {
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;

  thead {
    height: 53px;
    background-color: #6d7995;
    color: #fff;
    border-radius: 4px 4px 0px 0px;

    th {
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .heading_sort {
    cursor: pointer;
    color: #ff8800;
  }
  td {
    vertical-align: middle;
  }

  .element-logo {
    width: 50px;
  }

  .actions {
    i {
      color: #c95978;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .actions > * {
    margin: 0 10px;
  }
  .actions:first-child {
    margin-left: 0;
  }
  .actions:last-child {
    margin-right: 0;
  }

  .user-img-wrap {
    display: flex;
    align-items: center;

    .user-img {
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      float: left;
      margin-right: 5px;

      img {
        width: 100%;
      }
    }
    .user-name {
      color: #264b9a;
      font-weight: 600;
      // margin-top: 10px;
    }
  }
}

.tbl-sort-icon {
  cursor: pointer;
}

.search-input-wrap {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  height: 30px !important;

  .search-input {
    height: 30px;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: #6d799536;
  }

  .search-input-icon {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 36px;
    right: 0;
    top: 0;
    background-color: #74788d;
    padding: 5px;
    width: 40px;
    text-align: center;
    cursor: pointer;
    height: 30px;

    i {
      color: #fff;
      font-weight: bold;
      display: block;
      margin-top: -8px;
    }
  }
}

.add-btn {
  cursor: pointer;
  height: 30px;
  width: 100px;
  background-color: #cdf4e0;
  border-radius: 2px;

  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #00a20c;
  padding-top: 5px;
  overflow: hidden;
  font-size: 14px;

  .add-icon {
    padding: 10px;
    background-color: #00a20c;
    color: #f8f8fb;
    margin-right: 5px;
    font-size: 15px;
    font-weight: bold;
  }

  height: 30px !important;
}

.custom-drop {
  background: none;
  color: #777 !important;
  border: none;
}

.custom-drop:hover {
  background-color: transparent !important;
}

.attach-wrapper {
  border: 2px solid #ddd;
  border-style: dashed;
  border-radius: 10px;

  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 100px;
  .attach-label {
    margin: 0;
  }

  .img {
    width: 100%;
    height: 190px;
    color: #6d7995;
    font-size: 14px;
  }

  input {
    display: none;
  }

  .icon-cam {
    cursor: pointer;
    color: #6d7995;
    font-size: 30px;
    display: block;
  }
  .attach-holder {
    cursor: pointer;
    // width: 34px;
    // height: 23px;
    color: #6d7995;
    font-size: 14px;
  }
  .attach-icon {
    background: #264b9a;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    left: unset;
    bottom: -15px;
    right: -17px;
    padding-top: 3px;

    i {
      color: #fff;
      font-size: 20px;
    }
  }
}

.frm-label {
  color: #264b9a !important;
  font-weight: 400 !important;
}
.success-frm {
  background: #00a20c;
  width: 127px;
  margin-right: 15px;
}
.primary-frm {
  background: #264b9a;
  width: 127px;
  margin-right: 15px;
}

.client-logo-wrapper {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.client-logo-card {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;

  background-size: cover !important;
  background-position: center !important;

  img {
    width: 100%;
  }
}
.client-infos-card {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  .name {
    color: #264b9a;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 1.8em;
    line-height: 1.2;
    padding: inherit;
  }
  .attr-name {
    color: #6d7995;
    font-size: 12px;
  }

  .attr-val {
    color: #333;
    font-size: 12px;

    i {
      color: #264b9a;
      font-size: 11px;
      margin-right: 5px;
    }
  }
}

.drop-statuses {
  .dropdown-toggle {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

    border-radius: 20px;
    background: #efeff4 !important;
    color: #264b9a !important;
    padding: 5px 12px;
  }
}
.drop-statuses.white-toggle {
  .dropdown-toggle {
    background: #fff !important;
  }
}

.outline-table {
  thead {
    height: 53px;
    background-color: transparent;
    color: #264b9a;

    th {
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  thead:last-child {
    text-align: right;
  }

  td {
    vertical-align: middle;
  }

  .actions {
    i {
      color: #c95978;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .actions > * {
    margin: 0 10px;
  }
  .actions:first-child {
    margin-left: 0;
  }
  .actions:last-child {
    margin-right: 0;
  }

  .user-img-wrap {
    display: flex;
    align-items: center;

    .user-img {
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      float: left;
      margin-right: 5px;

      img {
        width: 100%;
      }
    }
    .user-name {
      color: #264b9a;
      // margin-top: 10px;
    }
  }
}

.card-document {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  border-radius: 5px;
  padding: 8px;

  .left-logo {
    color: #264b9a;
    font-size: 25px;
    float: left;
  }
  .name {
    color: #264b9a;
    font-size: 15px;
  }
  .doc-date {
    color: #6d7995;
    font-size: 10px;
  }
  .actions {
    text-align: right;
    i {
      color: #c95978;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .actions > * {
    margin: 0 4px;
  }
  .actions:first-child {
    margin-left: 0;
  }
  .actions:last-child {
    margin-right: 0;
  }
}

.card-user {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  border-radius: 5px;
  padding: 8px;
  background-color: #fff;

  .left-img {
    width: 100px;
    float: left;
    text-align: center;

    .img-wrap {
      width: 65px;
      border: 2px solid #ddd;
      border-radius: 100px;
      overflow: hidden;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .user-name {
      text-align: center;
      font-size: 15px;
      color: #264b9a;
    }
  }

  .infos {
    .attr {
      margin-bottom: 8px;

      .attr-icon {
        font-size: 12px;
        color: #264b9a;
        margin-right: 5px;
      }
      .attr-name {
        color: #6d7995;
        font-size: 13px;
      }
    }
  }

  .buttons {
    width: 100%;
    text-align: right;
  }
}

.mouvment-list {
  .mouvment {
    padding: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;

    .name {
      color: #333;
      font-weight: 500;
    }
    .from-to {
      color: #333;
    }
  }
}

.card-project {
  // display: flex;
  // flex-wrap: wrap;

  .project-logo {
    width: 60px;
    padding: 10px;

    img {
      width: 100%;
    }
  }

  .project-infos {
    padding-top: 10px;
    .name {
      color: $blue;
      font-weight: 500;
      margin: 0px;
    }
  }

  .project-actions {
    width: 20px;
    text-align: center;

    i {
      color: #c95978;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .project-footer {
    // padding: 10px 20px;
    // width: 100%;
    border-top: 1px solid #ddd;
    padding: 20px;
  }
}

.card-article {
  .article-name {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
  }
  .article-actions {
    text-align: right;

    i {
      color: #c95978;
      font-size: 18px;
      cursor: pointer;
      display: inline-block;
    }
    a {
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.actions-add {
  font-size: 25px;
  text-align: center;

  .actions-plus {
    color: #00a20c;
    cursor: pointer;
    padding: 0;
    margin-right: 5px;
  }
  .actions-close {
    color: #c95978;
    cursor: pointer;
    padding: 0;
  }
}

.center_both {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// GENERAL SETTINGS
.small-spinner {
  font-size: 6px;
  width: 12px;
  height: 12px;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.status-badge.badge-success {
  color: #00a20c;
  background-color: #00a20c5e !important;
}
.status-badge.badge-danger {
  color: #ff0000 !important;
  background-color: #ff000057 !important;
}
.status-badge.badge-warn {
  color: #ff8800 !important;
  background-color: #ff880061 !important;
}

.full-height-scroll {
  overflow-y: auto;
  height: 100vh;
}
.full-height-scroll::-webkit-scrollbar {
  width: 5px;
}
.full-height-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.full-height-scroll::-webkit-scrollbar-thumb {
  background: #888;
}
.full-height-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.h-90vh {
  height: 90vh;
}

.float-right {
  float: right !important;
}
.in-progress {
  color: #777;
  text-align: center;
  width: 100%;

  -webkit-text-fill-color: #f8f8fb; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #aaa;
}

.react-tel-input {
  input {
    width: 100% !important;
  }
}
.blue-sky {
  color: #11adb8 !important;
}
.burgundy-red {
  color: #8e104b !important;
}

.hilighted {
  color: #ff8800 !important;
}
.successed {
  color: #00a20c !important;
}
.grey {
  color: #6d7995 !important;
}

.dangered {
  color: #ff0000 !important;
}
.danger {
  color: #ff000057 !important;
}
.primed {
  color: $blue !important; //#264B9A
}
.position_switch {
  float: right !important;
}
.grevy-text {
  text-align: left;
  font: normal normal medium 15px/20px Poppins;
  letter-spacing: 0px;
  color: #6d7995;
  opacity: 1;
}
.react-switch-handle {
  height: 15px !important;
  width: 15px !important;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
  box-shadow: none !important;
}
.react-switch-bg {
  margin: auto !important;
  background: rgb(136, 136, 136) none repeat scroll 0% 0% !important;
}
.form-check-input:active {
  background-color: #264b9a !important;
}
.icon-success-time {
  top: 432px;
  left: 326px;
  width: 18px;
  height: 18px;

  opacity: 1;
}
.Edit-btn {
  cursor: pointer;
  height: 30px;
  width: 100px;

  border-radius: 2px;
  .edit-icon {
    padding: 10px;

    color: #f8f8fb;
    margin-right: 5px;
    font-size: 15px;
    font-weight: bold;
  }

  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  background-color: #8e104b !important;
  color: #f8f8fb !important;
  padding-top: 5px;
  overflow: hidden;
  font-size: 14px;
}

.hr-divider {
  .divider-name {
    display: inline-block;
    background-color: #fff;
    padding-right: 5px;
    z-index: 9;
    position: relative;
  }

  hr {
    margin-top: -18px;
    z-index: -1;
  }
}
.card-btn {
  border: 0.5px solid var(--unnamed-color-6d7995);
  border: 0.5px solid #6d7995;
  border-radius: 4px;
  opacity: 1;
  padding: 5px;
}
.rc-switch-checked .rc-switch-inner {
  left: 0px !important;
}
.rc-switch-inner {
  font-size: 11px !important;
  left: 22px !important;
}
.vertical-line {
  border-left: 0.5px solid #00000012;
  padding: inherit;
  padding-left: 50px;
  height: 100%;
  margin: 0px 20px;
}

.tab-th {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-11) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal 600 11px/17px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  border-bottom: 2px solid #264b9a !important ;
  border-radius: 5px;
}

.preview-edit-btn {
  text-align: right;
  padding: 5px;

  .txt-edit {
    color: #50a5f1;
    text-decoration: underline;
    cursor: pointer;
  }
}

.quote-percent-box {
  position: absolute;
  width: 275px;
  z-index: 9;

  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  border: 1px solid #ddd;
  border-radius: 5px;

  padding: 5px;
  background: #fff;
  margin-top: 5px;
  margin-left: -30px;

  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  .input-group {
    width: 150px;
    float: left;
  }
  .btn {
    margin: 0 !important;
    float: right;
    min-width: 100px;
  }
}

.tbl-add-clearance {
  // width: 100%;

  td,
  th {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
  }

  td,
  th:not(.note_column) {
    width: 150px !important;
    // white-space: nowrap;
  }

  th:last-child {
    width: 25px !important;
  }
  // th:nth-last-child(2) {
  //   width: 180px !important;
  // }

  .note_column {
    width: 180px !important;
  }

  .inner_table {
    width: 100%;

    .note_column {
      // width: auto !important;
      width: 180px !important;
    }
    td:last-child {
      width: 25px !important;
    }

    // td, th:not(.note_column){
    //   width: 150px !important;
    //   // white-space: nowrap;
    // }
  }

  select {
    text-align: center !important;
  }

  .td_works {
    width: 350px !important;

    // table{
    //   width: 196px !important;
    // }
  }
}

.collapse_wraper {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  min-height: 50px;

  .collapse_header {
    border-bottom: 1px solid #ddd;
    min-height: 25px;

    .title {
      cursor: pointer;
      color: #50a5f1;
      font-size: 15px;
      font-weight: 500;
      width: 100%;
    }

    .addition_head {
      float: left;
      display: inline-block;

      select {
        padding: 5px;
        border: none;
        padding: 5px;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }

    .actions {
      float: right;
      min-height: 20px;
      i {
        cursor: pointer;
      }
    }
  }
}

.report_project {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  min-height: 50px;

  .collapse_header {
    border-bottom: 1px solid #ddd;
    min-height: 25px;
    padding: 5px;

    .project_name {
      color: #264b9a;
      font-weight: 500;
      margin-right: 5px;
      font-size: 15px;
    }
    .project_ref {
      color: #777;
    }

    .header_left {
      display: inline-block;
    }

    .header_actions {
      float: right;
      min-height: 20px;

      i {
        cursor: pointer;
        font-size: 18px;
      }
    }
  }

  .collapse_body {
    padding: 5px;
  }
}

// .soft-scroll {
//   &::-webkit-scrollbar {
//     width: 3px;
//   }

//   ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }
//   ::-webkit-scrollbar-thumb {
//     background: #888;
//   }

//   ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
// }

.soft-scroll {
  // max-height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: #888;
    overflow: visible;
    // border-radius: 3px;
  }
  &::-webkit-scrollbar {
    // width: 0.4em;
    width: 4px;
  }
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: #888;
    overflow: visible;
    // border-radius: 3px;
  }
  &::-webkit-scrollbar {
    // width: 0.4em;
    width: 4px;
  }
}

.hide-alert-close {
  .close {
    display: none !important;
  }
}

.wrap-plan-modes {
  margin-top: 5px;

  .btn-plan-mode {
    cursor: pointer;
    width: 75px;
    color: #777;
    display: inline-block;
    margin: 0px 5px;
    padding: 5px 10px;
    text-align: center;
    font-size: 13px;
  }
  .btn-plan-mode.active {
    background-color: #264b9a;
    color: #fff;
    border-radius: 5px;
  }
}

.gantt_task_wraper {
  padding: 3px;
  background-color: #f8f8fb ;
  margin-top: 5px;
  margin-left: 5px;

  .split_task_icon {
    float: left;
    margin-left: -18px;
    cursor: pointer;
  }

  .main_task {
    background-color: #fff;
    // box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
    // box-shadow: 2px 8px 19px #B4B8BC29;

    box-shadow: 2px 8px 10px #b4b8bc29;
    margin-left: 0 !important;
  }

  .gantt_task_parent {
    border-radius: 3px;
    height: 30px;
    padding: 5px;
  }

  .gantt_task_child {
    padding: 5px;
    height: 30px;
  }

  .content {
    font-weight: 500;
    margin: 0;
    margin-top: -12px;
    padding: 0;
  }

  .task_toggle {
    float: left;
    margin-right: 5px;
    margin-top: 15px;

    width: 15px;
    height: 15px;
    background-color: #d8dce5;
    color: #6d7995;
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    text-align: center;

    i {
      display: block;
      margin-top: -15px;
    }

    i.toggle_task_icon_split {
      color: transparent;
    }
  }
  .task_point {
    color: #264b9a;
    font-size: 15px;
    float: left;
    margin-right: 5px;
    width: 20px;
    text-align: center;
  }

  .task_body {
    float: left;
    color: #23282d;
    font-size: 11px !important;

    .task_title {
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .task_team {
      float: left;
      // font-size: 15px;
      width: 80px;
      min-height: 40px;
      overflow: hidden;
      text-align: center;

      .staff_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-left: -10px;
        background-color: #b7b7b7;
        color: #747272;
        text-align: center;
        padding: 5px;
      }
      .staff_circle:first-child {
        margin-left: -0px;
      }
    }
    .task_date {
      float: left;
      // font-size: 15px;
      width: 60px;
    }
    .task_duration {
      float: left;
      // font-size: 15px;
      width: 40px;
      text-align: center;
    }
    .task_actions {
      float: right;
      font-size: 15px;
      width: 10px;
      text-align: right;
      cursor: pointer;
    }
  }

  .task_action_dialog {
    right: 0;
    height: auto !important;

    .dropdown-item {
      line-height: normal;
      padding: 5px;
    }
  }
}

.column_task_wrapper {
  color: #6d7995;

  .column_task_name {
    float: left;
    width: 230px;
    text-align: left;
    padding-left: 30px;
  }
  .column_task_team {
    float: left;
    width: 99px;
    overflow: hidden;
  }
  .column_task_date {
    float: left;
    width: 60px;
    overflow: hidden;
    text-align: left;
  }
  .column_task_duration {
    float: left;
    width: 45px;
    overflow: hidden;
  }
  .column_task_action {
    float: left;
    width: 30px;
    overflow: hidden;
  }
}

.tab-switcher {
  border: 1px solid #264b9a;
  border-radius: 5px;
  color: #264b9a;
  height: auto;
  overflow: hidden;
  width: 268px;

  span {
    text-align: center;
    padding: 5px;
    display: inline-block;
    width: 50%; // 130px;
    cursor: pointer;
    font-size: 12px;
  }

  .active {
    color: #fff;
    background-color: #264b9a;
    font-weight: 500;
  }
}
